import React, { Component } from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import Footer from '../components/footer'
import Seo from '../components/seo'
import pathspliter from '../functions/pathspliter'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

class Events extends Component {

  render() {
    const events = this.props.data.drupal.events.entities
    return (
      <div>
        <Seo canonical={`${this.props.pageContext.baseurl}${this.props.path}`} metainfo={this.props.data.drupal.metainfo.entities[0].entityMetatags} />
      <Header contactinfo={this.props.pageContext.contactinfo.entities[0]} langcode={this.props.pageContext.langcode} langcontainer={this.props.pageContext.langcontainer}  path={this.props.path} menu={this.props.pageContext.menu}/>
      <Breadcrumb path={pathspliter(this.props.path)} title={this.props.pageContext.title}/>
      <main>
      <Container>
          <section className="py-6">
            {events.map((item) => {
              if(!item){
                return
              }
              return (
                <div key={item.entityId} className="mb-5">
                  <h3>{item.fieldEventStartingDate.value}</h3>
                 <div className="p-md-5 p-3 greenparas">
                     <Row>
                     <Col md={4}>
                        <Img style={{maxWidth:'100%'}} fluid={item.fieldEventImage.entity.thumbnail.imageFile.childImageSharp.fluid} alt={item.fieldEventImage.entity.thumbnail.alt}/>
                     </Col>
                     <Col md={8}>
                     <h4 className="mt-3">{item.title} <span className="product-price">{item.fieldEventPrice}</span></h4>
                     <div className="info-item">

								  <div className="wrapper">
									<span> <i style={{color: "#e883ae"}} className="far fa-clock"></i> {item.fieldEventDates}</span>
								  </div>
								</div>
                <div className="info-item">

             <div className="wrapper">
             <span> <i style={{color: "#e883ae"}} className=" fas fa-map-marker-alt"></i> {item.fieldEventPlace}</span>
             </div>

              <div className="my-4" dangerouslySetInnerHTML={{ __html: item.body.processed }} ></div>
                {item.fieldEventFacebookLink ?
                  <a rel="noopener noreferrer" target="_blank" href={item.fieldEventFacebookLink}><button className="btn">{this.props.pageContext.langcode === "EN"? "MORE INFO": "ΠΕΡΙΣΣΟΤΕΡΑ"}</button></a>

                  : null}
           </div>

                     </Col>

                     </Row>

                 </div>
                 </div>
              )
            })}

          </section>
      </Container>
      </main>
        <Footer social={this.props.pageContext.social} langcode={this.props.pageContext.langcode}/>
      </div>
    );
  }

}

export default Events;


export const query = graphql`
  query($langcode: DRUPAL_LanguageId) {
    drupal {

      events: nodeQuery(filter: {conditions: [{field: "type", value: "event", language:$langcode}]}, sort: [{field: "field_event_starting_date"}]) {
      entities(language:$langcode) {

       ... on DRUPAL_NodeEvent {
         title
         entityId
         entityLanguage {
           id
         }
         body {
           processed
         }
         fieldEventDates
         fieldEventPlace
         fieldEventPrice
         fieldEventFacebookLink
         fieldEventStartingDate {
          value
        }
         fieldEventImage {
           entity {
             thumbnail {
               url
               alt
               imageFile {
                 childImageSharp {
                   fluid(quality: 80, maxWidth: 400,  maxHeight: 400, fit: COVER) {
                     ...GatsbyImageSharpFluid_withWebp
                     src
                   }
                 }
               }
             }
           }
         }
       }

      }
      }


      metainfo:nodeQuery(filter: {conditions: [{field: "type", value: "events_list"}]}) {
  entities(language:$langcode) {
    ... on DRUPAL_NodeEventsList {

      entityMetatags {
        value
        key
      }

    }
  }
}









}
  }
`

